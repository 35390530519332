
import { defineComponent } from '@nuxtjs/composition-api'
import { getIndirectClientHeaders, seoHead } from '~/assets/seo'
import { getBreadcrumbs, getHomeUrl, getSiteConfig } from '~/assets/craft'

export default defineComponent({
  name: 'Fallback',
  nuxtI18n: {
    paths: {
      nl: [
        '/www/:departmentTypeSlug(dierenambulance|dierenasiel|hondenschool|wildopvang)/:siteSlug/*',
        '/www/*',
        '/ikzoekbaas/*',
        '/mijndieriszoek/*',
        '/inspectiedienst',
        '/inspectiedienst/*',
      ].join('\\'),
    },
  },
  async asyncData({ error, redirect, req, res, route, store }) {
    const { site, siteGroup } = getSiteConfig(route)
    const [, , seoGlobal, page] = await Promise.all([
      store.dispatch('navigation/fetch', { site }),
      store.dispatch('global/fetch', { site, push: true }),
      store.dispatch('global/fetchSeo'),
      store.dispatch('page/fetchUri', { site, uri: route.params.pathMatch, push: true, options: { headers: getIndirectClientHeaders({ req }) } }),
    ])

    if (!page) {
      const seoRedirect = await store.dispatch('seo/fetch', { site, path: route.fullPath })
      if (seoRedirect) {
        res.setHeader('Cache-Control', 'max-age=600, must-revalidate, stale-while-revalidate=60')
        return redirect(seoRedirect.type, seoRedirect.to)
      }
      return error({ statusCode: 404, message: 'Page not found' })
    }

    // Some pages require an `await` for SEO purposes
    switch (page.section) {
      case 'animalHome':
        store.commit('variant-home-detail/initialize', { site })
        await store.dispatch('variant-home-detail/fetch')
        break
      case 'animalSuccessOverview':
        store.commit('animal-success-overview/initialize', route.query)
        await store.dispatch('animal-success-overview/fetch')
        break
      case 'articleOverview':
        store.commit('article-overview/initialize', { ...route.query, site, siteGroup })
        await Promise.all([store.dispatch('article-overview/fetchAllFacets'), store.dispatch('article-overview/fetch')])
        break
      case 'departmentOverview':
        store.commit('department-overview/initialize', { ...route.query, departmentType: page.departmentType.title, departmentVisibility: site })
        await store.dispatch('department-overview/fetch')
        break
      case 'jobOfferOverview':
        store.commit('job-offer-overview/initialize', { ...route.query, type: page.jobOfferType })
        await Promise.all([store.dispatch('job-offer-overview/fetchFacets'), store.dispatch('job-offer-overview/fetch')])
        break
      case 'pageOverview':
        store.commit('page-overview/initialize', route.query)
        await store.dispatch('page-overview/fetch')
        break
    }

    const fallbackComponentMap = {
      animalHome: 'TemplateVariantHomeDetail',
      animalOverview: 'TemplateAnimalOverview',
      animalSuccess: 'TemplateAnimalSuccessDetail',
      animalSuccessOverview: 'TemplateAnimalSuccessOverview',
      article: 'TemplateArticleDetail',
      articleOverview: 'TemplateArticleOverview',
      collectionPage: 'TemplateCollectionPage',
      departmentOverview: 'TemplateDepartmentOverview',
      jobOffer: 'TemplateJobOfferDetail',
      jobOfferOverview: 'TemplateJobOfferOverview',
      landing: 'TemplateLandingDetail',
      overview: 'TemplateOverview',
      page: 'TemplatePageDetail',
      pageOverview: 'TemplatePageOverview',
      special: 'TemplateSpecial',
    }
    const componentMaps = {
      inspectiedienst: {
        article: 'TemplateLidArticleDetail',
        articleOverview: 'TemplateLidArticleOverview',
        landing: 'TemplateLidLandingDetail',
        overview: 'TemplateLidOverview',
        page: 'TemplateLidPageDetail',
      },
    }
    const componentMap = { ...fallbackComponentMap, ...componentMaps[site] }

    const breadcrumbPrefixes = []
    switch (page.section) {
      case 'article':
        breadcrumbPrefixes.push({ label: 'Actueel', url: getHomeUrl(route, '/actueel').url })
        break
    }

    return {
      breadcrumbs: getBreadcrumbs(route, page, breadcrumbPrefixes),
      componentMap,
      page,
      seoGlobal,
    }
  },
  head() {
    const options = {}
    switch (this.page.section) {
      case 'animalOverview':
      case 'articleOverview':
      case 'departmentOverview':
      case 'jobOfferOverview':
      case 'pageOverview':
        options.canonicalQuery = Object.fromEntries(['page'].map((query) => [query, this.$route.query[query]]))
        break
    }

    return seoHead(this.page, this.seoGlobal, options)
  },
  httpHeaders: () => ({
    'Cache-Control': 's-maxage=21600, stale-while-revalidate=86400, stale-if-error=86400',
  }),
})
